.m-game-grid-button {
  height: 36px;
  line-height: 0;
  border-radius: 6px;
  color: lighten($teritary, 20%) !important;
  border-color: lighten($teritary, 20%) !important;

  &:hover {
    background-color: darken($teritary, 30%) !important;
    color: white !important;
  }
  &:focus {
    background-color: #1E2432 !important;
    color: white !important;
  }
  &.button {
    font-weight: 500;
    text-transform: lowercase;
  }
}

.button {
  border-radius: 8px;
  font-weight: 600;
  outline: 0;
  text-transform: uppercase;

  transition: all ease 250ms;

  &:hover {
    transition: all ease 250ms;
  }

  &.is-rounded {
    border-radius: 50px;
  }

  &.is-loading::after {
    border-radius: 50%;
    border: 2px solid $success;
    border-right-color: transparent;
    border-top-color: transparent;
    border-color: transparent transparent #9bc0cf #9bc0cf !important;
  }

  &.is-dark.is-loading::after {
    border-color: transparent transparent $success $success !important;
  }

  &.is-dark {
    background-color: $dark;
    border: 1px solid $dark;
    transition: all ease 250ms;
    &:hover {

      background-color: #474747;
      border: 1px solid #A4A09F;
      transition: all ease 250ms;

    }
  }

  &.is-secondary-bg {
    color: white;
    background-color: $button-secondary-background;
    border-color: $button-secondary-background;

    &:hover {
      background-color: darken($button-secondary-background, 10%);
      color: white;
      border-color: darken($button-secondary-background, 10%);
    }
  }

  &.m-header-sign-up-button {
    .m-header-account-sign-in & {
      background-color: $success;
      color: white;

      &:hover {
        background-color: darken($success, 1);
      }
    }
  }
  &.is-dark-green,
  &.is-success {
    background-color: $dark-green;
    color: white;
    border-color: $dark-green;
    transition: all ease 250ms;

    &:hover {
      transition: all ease 250ms;
      background-color: $dull-green;
      border-color: $dark-green;
    }

    &:disabled {
      background-color: $dark-green;
    }
  }
  &.is-dark-blue {
    background-color: $button-blue;
    color: white;
    border-color: $button-blue;
    transition: all ease 250ms;

    &:hover {
      transition: all ease 250ms;
      background-color: $button-blue-onhover;
      border-color: $button-blue;
    }
  }
  &.is-full-width {
    width: 100%;
  }
  &.is-wide {
    min-width: 350px;
  }
  &.is-wide-desktop {
    @include from($desktop) {
      min-width: 350px;
    }
  }
  &.is-centered {
    margin-left: auto;
    margin-right: auto;
  }
  &.is-maintenance {
    height: auto;
    display: block;
    pointer-events: none !important;
    padding: 5px !important;

    i {
      @include from($tablet) {
        margin-right: 0;
      }
    }
  }
  &.is-xlarge-fullhd {
    @include from($fullhd) {
      border-radius: 100px;
      min-width: 350px;
    }
  }
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.is-tiny-touch {
    @include until($desktop) {
      font-size: 10px !important;
      display: block
    }
    @include until(600px) {
      table & {
        margin-top: -3px;
        margin-bottom: -3px;
      }
    }

    &.is-primary {
      &:hover {
        background-color: darken($primary, 10%);
      }
    }
  }

  &.is-outline-dark {
    border-color: $dark !important;
    &:hover {
      background-color: darken($primary, 10%);
    }  
  }

}
.react-cookie-banner {
  background-color: $brand-grey;
  .button-close {
    @extend .button;
    @extend .is-success;
    text-transform: uppercase;
  }
}

a.m-button-scroll-to-top,
.m-button-scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background: #1e2432;
  color: $white;
  border: 4px solid #1e2432;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  z-index: 99;
  transform: scale(0.75);
  i {
    font-size: 30px;
  }
  @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
    bottom: 50px;
  }
}

.step-content {
 .button {
   text-decoration: none !important;
 }
}

.m-game-play-button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0,0,0,0.5);
  text-align: center;
  color: $success;
  border: 2px solid $success;
  border-radius: 50px;
  margin: auto auto 20px;

  i {
    margin: 0 0 0 3px !important;
  }

  &:hover {
    color: $primary;
    border-color: $primary;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    .m-demo-link {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.m-button-arrow {
  background: $dark;
  border: 0px solid;
  border-radius: 20px;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  i {
    pointer-events: none;
  }
  &:hover {
    background: $grey;
  }
}

.button-disabled-resend {
  pointer-events: none;
  cursor: not-allowed !important;
  text-decoration: none !important;
  opacity: 0.4 !important;
}



.m-bonus-preselector-button {
  border-radius: 100px;
  border-color: white !important;
  background-color: transparent;
  color: white;
  &:hover,
  &.is-disabled {
    background-color: rgba(255,255,255,0.15);
    color: white;
  }
  @include until($tablet) {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    min-height: 45px !important;
    margin-left: 1rem !important;
  }
}
