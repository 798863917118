.m-grid-item {
    position: relative;

    &-caption {
        padding: $gap;
        background-color: rgba(0,0,0,1);
        color: white;
        line-height: 1.3;
        height: 75px;
        position: relative;
        
        @supports not (backdrop-filter:blur(90px) saturate(2)) {
          background-color: rgba(0, 0, 0, 0.15);
        }
        @supports (backdrop-filter:blur(90px) saturate(2)) {
          background-color: transparent;
          backdrop-filter: blur(90px) saturate(2);
        }
        
        text-shadow: 0 0 2px black;

       
      }

    .m-grid-item-calendar-day {
      position: absolute;
      z-index: 1;
      transform: rotate(-45deg) scale(1.5);
      width: 170px;
      text-align: center;
      left: -40px;
      height: 80px;
      padding-top: 10px;
      top: -54px;
      span {
        font-size: 18px;
        transform: rotate(45deg);
        display: block;
        margin-top: 37px;
        margin-left: -57px;
        font-family: "Montserrat", sans-serif;
      }
    }

    &-icon {
        position: absolute;
        top: -2px;
        right: -2px;
        font-size: 4rem;
        color: white;
        line-height: 0;
        border-radius: 50%;
        text-align: left;
        overflow: hidden;
        letter-spacing: 1px;

        &:before {
            content: '';
            background-color: black;
            display: block;
            width: 60px;
            height: 60px;
            position: absolute;
            top: 2px;
            border-radius: 50%;
            right: 2px;
        }

        i {
            position: relative;
        }
    }
}

.m-grid-item-calendar-day {
    background-color: $primary;
    color: white;
    font-size: 50px;
    padding: 0 10px;
    font-weight: bold;
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
    line-height: 1.3;
    letter-spacing: -1px;
}

.m-grid-row-seperator {
    padding: 0 $gap/2 $gap*3;

    @include until($desktop) {
        padding: $gap*2 $gap/2 $gap*3;

        .m-promo-section & {
            order: 3 !important;
        }
    }
}

.m-grid-item-caption-overlay {
  position: absolute;
  width: 100%;
  height: 80px;
  background-size: 100%;

  @supports not (backdrop-filter:blur(90px) saturate(2)) {
    filter:blur(90px) saturate(2);
  } 
  
  &:after {
    content: '';
    position: absolute;
    display: block;
  
    width: 100%;
    height: 80px;
  }
}

.m-grid-item-promotions {
  height: 100%;

  img { height: 100% }

  .m-grid-item-caption {
    backdrop-filter: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.3);
    height: auto;
  }
}