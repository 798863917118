// =========================================================================
// Layout - Sections of Page
// =========================================================================

// =========================================================================
// Site Header
// =========================================================================

.header.is-fluid.container {
    max-width: $container-width !important;
    margin: auto;
    padding: 15px $gap;

    @include until($desktop) {
        box-shadow: 0 0 30px rgba(0,0,0,0.4);
        position: static;
        top: 0;
        z-index: 32;
        width: 100%;
        background-color: $brand-dark;
        padding: 0;
    }

    @include until($tablet) {
        padding-right: 0;
    }

    @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
      position: static;
    }
}

.m-site-header {
    background-color: $brand-dark;
    width: 100%;
    top: 0px;

    @include until($desktop) {
        position: fixed;
        z-index: 99;
    }
    
    .navbar-burger {
        color: white;
    }
}

.m-header-account-sign-in {
    @include until($desktop) {
        padding-right: 0.5rem;
    }

    @include until($tablet) {
        padding-right: 0rem;
    }

    span {
        color: lighten($light, 10%) !important;
    }
    .field {
        margin-top: 0 !important;
        padding: 0 5px;
    }
    .input {
        box-shadow: 0 0 0;
        @include until(1400px) {
            width: 11vw;
        }
    }
    .help {
        position: absolute;
        margin-top: 0;
        color: $primary;
        &:hover {
            text-decoration: underline;
        }
    }

    .button.is-dark-green {
        @extend .is-dark-green;
    }
    
    #logout {
        min-width: 94px;
        margin-right: $gap;
        color: white;

        &:focus {
            border-color: #dbdbdb;
        }

        &:hover {
            background: $brand-grey;
        }
    }

    #signout {
        color: white !important;
    }
    .button {
        padding: 0 1.5rem;
        @include until(1400px) {
            padding: 0 1vw;
        }

    }
    .icon {
        opacity: 0.5;
    }

    .m-balance .icon {
        font-size: 1.25rem;
    }
}

.m-login-fields {
    .field {
        max-width: 130px;
    }
}

.header.container.is-fluid.rtp {
    height: 75px;
    padding-bottom: 0;

    @include until($tablet) {
        height: initial;
    }

    @include until($desktop) {
        padding-left: 1rem;
    }

    @include from($desktop) {
        padding-bottom: 1rem;
    }

    .level.is-mobile {
        height: 100%;
    }
}

.m-top-nav-brand.rtp {
    justify-content: flex-start;
    height: 83px;
}

.m-account-menu-item {
    color: $white;
    svg {
        width: 1.3rem;
        vertical-align: top;
        margin-right: 0.5rem;
    }
    path {
        fill: white;
    }
    &:hover {
        path {
            fill: black;
        }
    }

    .icon-sign-out {
        margin-top: 0.15rem;
        transform: rotate(180deg);
    }
}

.m-account-dropdown-menu {
    .dropdown {
        &.is-active {
            .button {
                border-radius: 8px 8px 0 0;
            }
        }
        .dropdown-trigger > button {
            padding: 0 1.5rem;
            &:hover {
                background-color: rgba(255,255,255,0.1);
            }
        }
    }
    .dropdown-menu {
        width: 100%;
        min-width: 0;
        padding: 0;
    }
    .dropdown-content {
        padding: 0;
        border: 1px solid white;
        border-top: 0 solid;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
    }
    .dropdown-item {
        border-bottom: 1px solid white;
        &:last-child {
            border-bottom: 0px solid;
        }
    }
    button.dropdown-item {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        &:hover {
            background-color: white;
        }
    }
}

.m-header-balance-wrapper {
    display: block;
    position: relative;
    top: 0.5rem;
    white-space: nowrap;

    .buttons {
        margin-bottom: 0;
        display: block;
    }

    .button {
        margin-bottom: 0;
        width: 100%;
    }
}

.m-header-balance {
    font-size: 0.75rem;
    background-color: transparent;
    border: 0px solid;
    cursor: pointer;
    display: block;
    margin: auto;
    position: relative;
}