ul.pagination-list {
  list-style: none !important;
  justify-content: center;

  @include until($tablet) {
    font-size: 13px;
  }

  > li {
    margin-top: 0px;
    a {
      @extend .pagination-link;
      text-decoration: none !important;

      @include until($tablet) {
        padding: 5px;
        font-size: 12px;
        margin: 2px;
      }
    }

    &.is-current {
      a {
        color: white !important;
        background-color: $success;
        border-color: lighten($grey, 20%);
      }
    }
  }

  .previous,
  .next {
    a {
      @extend .button;
      @extend .is-success;
      color: white !important;
      @include until($tablet) {
        padding: 5px;
        font-size: 12px;
      }
    }
    &.disabled {
      opacity: 0.5;
      a {
        pointer-events: none;
      }
    }
  }

  .previous {
    margin-right: auto;

    a {
      padding-left: 10px;
      padding-right: 10px;
      &:before {
        font-family: 'Font Awesome 5 Free';
        @extend .fa-arrow-left;
        margin-right: 10px;
      }
    }
  }

  .next {
    margin-left: auto;

    a {
      padding-left: 10px;
      padding-right: 30px;
      @include until($tablet) {
        padding: 5px 30px 5px 10px;
        font-size: 12px;
      }
      &:before {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        @extend .fa-arrow-right;
        margin-left: 10px;
        right: 10px;
      }
    }
  }
}
.pagination-list {
  list-style: none !important;

  a {
    text-decoration: none !important;
    &.is-current {
      color: white !important;
    }
  }

  li {
    margin-top: 0 !important;
  }
}
.pagination-link {
  &.is-current {
    border-color: transparent;
    text-decoration: none !important;
  }
  border-color: transparent;
  text-decoration: underline !important;
  &:hover {
    border-color: transparent;
  }
}

.pagination-previous,
.pagination-next {
  color: $primary !important;
  &:hover {
    color: lighten($brand-grey, 60%);
  }
}
