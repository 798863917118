.m-game-nav {
  line-height: $navbar-height;
  padding: 0 $gap;

  .navbar-burger {
    color: white;
  }

  &.is-fluid {
    max-width: $container-width !important;
    margin-left: auto;
    margin-right: auto;

    @include until($tablet) {
      padding: 0 $gap/2 0 $gap/2;
    }

    > .level {
      @include until(375px) {
        justify-content: space-between;
      }
    }
  }

  &-parent.is-active {
    margin-bottom: 50px;
  }

  &-parent {
    background-color: rgba(255,255,255,0.05);
  }

  .navbar-item {
    color: $white;
    transition: background-color ease 250ms;
    background: transparent;
    border: 0px solid;
    font-size: inherit;
    cursor: pointer;

    &.is-active {
      background-color: white;
        color: black;
    }

    @include until(1305px) {
      font-size: 13px;
      padding-left: $gap/2;
      padding-right: $gap/2;
    }

    @include until($desktop) {
      font-size: inherit;
      color: $dark;

      &.is-active {
        background-color: $brand-grey;
        color: white;
      }
    }

    &:hover {
      background-color: white;
      transition: background-color ease 250ms;
      color: $dark;

      @include until($desktop) {
        background-color: $light;
        color: $brand-dark;
      }
    }
  }

  .level-right {
    align-items: center;
    line-height: $navbar-height;
  }

  &-button {
    .navbar-burger {
      pointer-events: none;
    }

    @include until($tablet) {
      padding: 0 0 0 5px;

      .navbar-burger {
        width: 2.5rem;
      }
    }
  }

  &-menu {
    border: 0px solid;
    border-radius: 0;

    > .navbar-item {
      @include until(1070px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @include until($desktop) {
      display: none;
    }

    &.is-active {
      .navbar-item.is-active {
        color: $white !important;
      }
    }

    &.is-active {
      .navbar-item {
        width: 100%;
      }

      @include until($desktop) {
        .active {
          &.navbar-item.is-active:not(:focus):not(:hover) {
            background-color: $brand-grey;
            color: $white;
          }
        }
        display: block;
        position: absolute;
        top: $navbar-height;
        width: 200px;
        background: white;
        box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      }

      @include until($desktop) {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
      }
    }

    &-label {
      line-height: $navbar-height;
      color: $white;
    }

    .navbar-item {
      @include until($desktop) {
        padding: 0.375rem 1rem;
        &.is-active {
          background-color: $brand-grey !important;
        }
      }
    }
  }

  &-search {
    line-height: $navbar-height;
    overflow: hidden;

    .m-game-nav-search-field {
      @include until($desktop) {
        position: absolute;
        right: 0;
        padding: $gap;
        top: $navbar-height;
        z-index: 2;
        visibility: hidden;

        &.is-active {
          top: $navbar-height;
          background: $primary;
          width: 100%;
          right: 0;
          transition: all ease 300ms;
          visibility: visible;
        }

        &.is-inactive {
          height: 0;
          width: 0;
          overflow: hidden;
          position: fixed;
        }
      }
    }

    &-button {
      line-height: $navbar-height;
      padding-top: 0;
      padding-bottom: 0;
      background-color: $brand-grey;
      border-color: $brand-dark;
      color: $white;
    }

    .input {
      color: $light;
    }

    .control.has-icons-left .input:focus ~ .icon {
      color: $light;
    }
  }

  &-secondary {
    &.navbar-item {
      &:hover {
        background-color: transparent;
      }

      .navbar-item {
        padding: 0.375rem 1rem;
        color: white;
        &.active {
          background-color: white;
          color: $brand-grey;
        }
      }
    }
    .m-game-nav-menu {
      &.is-active {
        .navbar-item {
          @include from($desktop){
            color: white !important;
            &:hover {
              color: $dark !important;
            }
          }
          &.is-active.active {
            @include until($desktop) {
              color: $brand-dark;
            }
          }

          &:hover {
            &.is-active.active {
              color: $brand-dark;
            }
          }
        }
      }

      @include until($desktop) {
        right: 0;
        left: auto;
      }
    }
  }
}

.m-top-nav-sub-area .box .button {
  vertical-align: baseline;
}

.m-game-search-field {
  background-color: $dark;
  color: rgba(255,255,255,0.5);

  &::placeholder {
    color: rgba(255,255,255,0.5);
  }

  + .icon {
    opacity: 0.5;
  }
}

.m-game-nav-button {
  color: $white;
  &:hover {
    background: transparent;
  }
}

.m-top-nav-sub-area.m-top-nav {
  min-height: 55px;
  @include until($desktop) {
    position: relative;
    left: 0;
    right: 0;
  }
}

.m-top-nav-game-menu {
  @include from(1172px) {
    padding-left: 170px;
  }
  @include from($desktop) {
    display: flex;
  }
  .m-top-nav-item {
    &:first-of-type {
      @include from($desktop) {
        margin-left: 80px;
      }
      @include from($widescreen) {
        margin-left: 170px;
      }
    }
  }
}


button.m-game-nav-button {
  background-color: transparent;
  border: 0px solid transparent;
  align-items: center;
  line-height: 0;
  outline: 0;
  outline: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: inherit;
  cursor: pointer;
}

.m-game-nav-secondary {
  .m-game-nav-menu.is-active {
    display: block;
    position: absolute;
    top: $navbar-height;
    width: 200px;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    .navbar-item {
      &:not(.active) {
        color: $dark;
      }

      &:hover {
        color: $brand-grey;
        background-color: $light;
      }
      &.is-active,
      &.active {
        background-color: $brand-grey;
        color: white !important;
      }
    }
  }
}
