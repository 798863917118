// =========================================================================
// Layout - Sections of Page
// =========================================================================

.main-content-parent {

  @include until($desktop) {
    margin-top: 80px;
    z-index: 1 !important;
  }

  @include until($tablet) {
    //min-height: calc(100vh * 2);
  }

  @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
    margin-top: 0px;
  }
}

.main-content-parent-short {
  min-height: 0;
}
.main-content-parent-short + .m-seo-accordion {
  background-color: black;
}

.home-page-wrapper {
  min-height: calc(100vh + 200px);

  @include until($desktop) {
    overflow-x: hidden;
    min-height: 0;
  }
  .m-seo-accordion {
    background-color: $dark;
  }
  @include until($tablet) {
    .main-content-parent {
      min-height: calc(100vh + 200px);
    }
  }
  .main-content-parent {
    min-height: calc(100vh + 200px);
  }
}

.games-page-wrapper {
  min-height: calc(100vh + 200px);
  margin-top: 42px;
  
  @include until($desktop) {
    overflow-x: hidden;
    min-height: 0;
  }
  .m-seo-accordion {
    background-color: $brand-mid-grey;
  }
  @include until($tablet) {
    .main-content-parent {
      min-height: calc(100vh + 200px);
    }
  }
  .main-content-parent {
    min-height: calc(100vh + 200px);
  }
}

.row {
  padding: 0 $gap;

  &-touch {
    @include until($desktop) {
      padding: 0 $gap;
    }
  }
}

.section {
  @include until($tablet) {
    padding: 0 $gap;
  }
}

.m-grid-item {
  margin: $gap/2;
  overflow: hidden;

  > img {
    vertical-align: top;
    width: 100%;
  }
}

.section {
  > .container.is-fluid {
    max-width: $container-width !important;
    margin: auto;
    padding: 0 $gap;
  }
}

.m-content-top-padding {
  padding-top: $gap * 2;
}
.m-content-top-padding-x2 {
  padding-top: $gap * 4;
}

.filtered-game-section {
  padding-top: 1.5rem;
}

.m-page-width-wrapper.column {
  @include from($desktop) {
    width: 840px;
  }
}

.m-notification-logged-in-error {
  padding-top: 100px;
}

.container.is-fluid {
  max-width: $container-width;
  margin-left: auto !important;
  margin-right: auto !important;
}

.skeleton-loading {
  background-color: #e2e5e779;
	// The shine that's going to move across the skeleton:
	background-image:			
			linear-gradient(
				90deg, 
				rgba(#e2e5e779, 0), 
				rgba(#e2e5e779, 0.5),
				rgba(#e2e5e779, 0)
			);
	background-size: 40px 100%; // width of the shine
	background-repeat: no-repeat; // No need to repeat the shine effect
	background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
	animation: shine 2s ease infinite; // increase animation time to see effect in 'slow-mo'

  padding-bottom: 69.5%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    height: 100%;
}
@keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -40px top 0;
	}
}