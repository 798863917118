.m-tool-bar {
  button {
    background: transparent;
    border: 0 solid;
    font-size: inherit;
    outline: 0;
    cursor: pointer;

    &:hover {
      background-color: darken($primary, 18%);
    }
  }

  &.is-fixed-bottom {
    @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
      position: static;
    }
  }

  @include until($tablet) {
    .container.is-fluid {
      padding-left: 0;
      padding-right: 0;
    }
    a,
    button.navbar-item {
      font-size: 13px;
    }
  }

  .is-up {
    .m-top-nav-item {
      pointer-events: none;
      min-height: $navbar-height;
    }

    .dropdown-menu {
      padding-bottom: 0;
    }

    .dropdown-trigger {
      cursor: pointer;

      &:hover {
        background-color: darken($primary, 18%);
      }
    }
  }
}

.m-support-tab,
.m-previously-played {
  &-container {
    position: fixed;
    z-index: 1;
    bottom: -8px;
    left: 10px;
    right: 10px;
    text-align: right;
    pointer-events: none;

    &.is-fluid.container {
      width: auto;
    }

    button.navbar-item {
      background: transparent;
      border: 0px solid;
      outline: 0;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: darken($brand-blue, 18%);
      }
    }
  }

  &.is-active {
    height: 375px;
    transition: height ease-in 500ms;
    transition-timing-function: cubic-bezier(0.27, 0.33, 0.5, 1.5);
  }

  transition: height ease-out 500ms;
  transition-timing-function: cubic-bezier(0.27, 0.33, 0.5, 1.5);

  height: $navbar-height;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  background-color: $brand-blue;
  display: inline-block;
  margin-left: auto;
  pointer-events: auto;
  border-radius: 8px 8px 0 0;
  
  .navbar-item {
    color: white;
    padding: $gap $gap * 2;
    font-size: inherit;
    text-shadow: 0px 1px 1px #000000;

  }

  &-icon {
    background: $brand-dark;
    border-bottom: 2px solid #ffffff;
    border-radius: 100px;
    display: block;
    font-size: 1.5em;
    height: 60px;
    line-height: 60px;
    margin: 0 auto 2px auto;
    width: 60px;
    color: white;
    
    &:hover {
      background-color: $brand-grey;
      color: white;
    }
  }

  &-content {
    padding: $gap * 2;
    min-height: 197px;
    top: 1px;
  }

  a, button {
    color: $link;
  }

  li {
    margin-bottom: $gap;
  }

  .live-chat {
    padding: 0;
    font-size: 1em;
    background: transparent;
    border: 0px solid;
    cursor: pointer;
    outline: 0;
  }
}
