.m-promo-news-banner-area {
    margin: $gap/2;
}

.m-promo-news-banner {
    margin: $gap 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    @include from($tablet) {
        min-height: 470px;
        padding-top: 50px;
        padding-bottom: 50px;
        overflow: visible;
    }
    @include until($tablet) {
        position: relative;
        padding-bottom: 20px;
        > .columns {
            align-self: center;
        }
    }
    min-height: 300px;

    .m-blog-layout &,
    .m-blog-article-layout & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .columns {
            margin-bottom: $gap*7;
        }
    }

}


.m-promo-news-banner-title {

    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
    line-height: 1.125;
    background-color: transparentize($dark, 0.3);
    @include from($tablet) {
      display: inline-flex !important;
      align-self: flex-start;
    }

    .m-blog-layout &,
    .m-blog-article-layout & {
        @include from($tablet) {
            max-width: fit-content;
        }
    }

    @include from($tablet) {
      padding-left: 25px;
      margin-left: -$gap;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 10px;
      padding-top: 10px;
      display: inline-block;
    }

    .content & {
        color: $brand-dark;
    }
}

.m-promo-news-banner-sub-title {
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-weight: 600;
    line-height: 1.125;
    margin-bottom: 0.25em !important;
}

.m-promo-tall-banner {
    padding: $gap*3 $gap*4;
    min-height: 740px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
}

.m-promo-blog-title-banner {
    min-height: 200px;
    background-color: $primary;
    background-position: right 0 top;
    background-size: 1196px 100%;
    padding: 0 30px 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin: $gap/2 $gap/2 $gap*4;

    @include until($tablet) {
        padding: 0 $gap*2 $gap*2;
    }
}

