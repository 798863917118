.m-tool-tip {
  position: relative;

  &-icon {
    background-color: $primary;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
    line-height: 20px;
    font-weight: 600;
    position: absolute;
    top: -23px;
    right: -19px;
    cursor: pointer;
    &:hover {
      background-color: darken($primary, 10%);
    }
  }

  &-text {
    display: none;
  }

  &-icon:hover + .m-tool-tip-text {
    display: block;
    background: $light;
    color: $dark;
    position: absolute;
    bottom: 30px;
    transform: translateX(-50%);
    padding: 8px 5px;
    border-radius: 5px;
    min-width: 400px;
    font-size: 13px;
    &:after {
      content: '\f0d7';
      font-family: 'Font Awesome 5 Free';
      font-size: 40px;
      color: $light;
      display: block;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-3px);
      pointer-events: none;
    }

    .m-cashier-balance & {
      @include until($desktop) {
        bottom: auto;
        top: 0px;
        border: 1px solid $light;
        min-width: 240px;
        line-height: 1.25;

        &:after {
          content: '\f0d8';
          top: -30px;
        }
      }

      @include until(500px) {
        right: -170px;

        &:after {
          left: 79%;
        }
      }
    }
  }

  &-logo:hover + .m-tool-tip-text {
    display: block;
    background: $primary;
    color: $dark;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 4px;
    border-radius: 5px;
    min-width: 100px;
    font-size: 13px;
    line-height: 30px;
    white-space: nowrap;
    &:after {
      content: '\f0d7';
      font-family: 'Font Awesome 5 Free';
      font-size: 40px;
      color: $primary;
      display: block;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
    }
  }
}
