.m-source-of-wealth-wrapper {
  .content {
    &__title-wrapper {
      display: flex;
    }

    &__sub-title {
      &:after {
        content: none !important;
      }
    }

    &__survey-questions,
    &__survey-question {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__survey-questions {
    }

    &__survey-question {
      width: 100%;
      margin-bottom: 2rem;

      &:first-child {
        margin-top: 2rem;
      }

      &__title,
      &__sub-title {
        color: #fff !important;
        text-align: initial;
        padding: 0;
        position: relative;

        & > span {
          position: absolute;
          margin-left: 0.2rem;
          color: rgb(207, 0, 0);
          font-size: 1.5rem;
        }

        &:after {
          content: none !important;
        }
      }

      &__sub-title {
        margin-top: 0 !important;
        color: #fff !important;
        text-align: initial;
        padding: 0;

        &:after {
          content: none !important;
        }
      }

      &__answers {
        list-style: none;
        margin: 1rem;

        @include until($tablet) {
          width: 90vw;
        }
      }

      &__answer {
        margin-bottom: 0.5rem;
      }

      &__answer-content {
        display: flex;

        .answer {
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
          cursor: pointer;
          width: 100%;

          @include until($tablet) {
            display: block;

            #checkbox {
              margin-bottom: 0.5rem;
            }
          }

          label {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            padding-left: 30px;

            input[type='checkbox'] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;

              &:checked {
                & ~ .checkmark {
                  background-color: transparent !important;
                  border: 3px solid #fff;
                  border-radius: 3px;

                  &:before {
                    position: absolute;
                    content: '';
                    opacity: 1;
                    left: 7px;
                    top: -10px;
                    width: 9px;
                    height: 22px;
                    border: solid 3px $brand-blue;
                    border-radius: 2px;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                    z-index: 1;
                  }

                  &:hover {
                    background: transparent;
                  }
                }
              }
            }

            &#checkbox {
              position: relative;
              cursor: pointer;

              .checkmark {
                content: '';
                position: absolute;
                left: 0;
                height: 20px;
                width: 20px;
                border-radius: 2px;
                background-color: #eee;
              }
            }

            &#textarea {
              padding: 0;

              textarea {
                width: 100%;
                max-width: 380px;
                padding: 0.5rem;
                border: none;
              }
            }

            input[type='radio'] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;

              &:checked {
                & ~ .customRadioBtn {
                  background-color: transparent !important;
                  border: 3px solid #fff;
                  border-radius: 3px;

                  &:before {
                    position: absolute;
                    content: '';
                    opacity: 1;
                    left: 4px;
                    top: 4px;
                    width: 6px;
                    height: 6px;
                    border: solid 3px #28b2f0;
                    border-radius: 50%;
                    z-index: 1;
                  }
                }
              }
            }

            &#radiobutton {
              position: relative;
              cursor: pointer;

              .customRadioBtn {
                content: '';
                position: absolute;
                left: 0;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 3px solid #fff;
              }
            }

            &:hover {
              &#checkbox {
                .checkmark {
                  background-color: darken(#eee, 20%);
                }
              }
              &#radiobutton {
                .customRadioBtn {
                  border-color: darken(#eee, 20%);
                }
              }
            }
          }

          input[type='text'] {
            padding: 0.2rem 0.3rem;
            width: 100%;
            max-width: 350px;

            @include until($tablet) {
              width: 90vw;
              height: 40px;
            }
          }

          &.document-type {
            padding: .5rem 1rem;
            border-radius: 5px;
            max-width: 400px;
            margin-bottom: 1rem;
            background: darken(#1E2432, 8%);
            border: none;
            color: white;

            &:active,
            &:focus {
              outline: none;
            }
          }

          &.file-upload {
            flex-direction: column;
            width: 100%;

            & > ul {
              list-style-type: disc;
            }

            .button-wrapper {
              display: flex;
              align-items: center;
            }

            .files-wrapper {
              margin: 1rem 0;

              .image {
                border-radius: 5px;
                box-shadow: 10px 10px 23px -1px rgba(18, 20, 28, 0.75);
                -webkit-box-shadow: 10px 10px 23px -1px rgba(18, 20, 28, 0.75);
                -moz-box-shadow: 10px 10px 23px -1px rgba(18, 20, 28, 0.75);

                &:not(:last-child) {
                  margin-bottom: 1rem;
                }

                img {
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }

                span {
                  font-size: 1.1rem;
                  padding: 0.5rem 1rem;
                  display: block;
                  background-color: lighten($scheme-main, 5%);
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }
            }

            .notification.is-danger {
              margin: 1rem 0;
            }
          }

          &.list-item {
            & > li {
              position: relative;
              padding-left: 2em;

              &:before {
                position: absolute;
                content: '';
                left: 1em;
                top: 50%;
                transform: translateY(-50%);
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $primary;
              }
            }
          }
        }
      }
    }

    &__footer {
      &__message {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 1.5rem;
      }

      .button-wrapper {
        display: flex;
        justify-content: center;

        .button {
          margin: 1rem 0 !important;
        }
      }
    }

    .survey-info {
      display: flex;
      justify-content: center;
      margin: 3rem 0;
      padding: 3rem 2rem;
      background: $primary;
      border-radius: 10px;
      color: black;
      font-size: 1.2rem;
    }
  }
}
