.m-steps {
    margin-left: -64px;
    margin-right: -64px;
    color: white;
    @include until($tablet) {
        margin-left: -24px;
        margin-right: -34px;
        margin-top: 10px;
    }

    &.is-medium {
        .step-item .step-details {
            margin-top: 0;
            padding: 0;
            padding-bottom: .2em;
            margin: 0;
            height: 100%;
        }

        .step-item:not(:first-child)::before {
            top: 3.7rem;
        }
    }

    .step-item {
        min-width: 25%;
    }


    .step-done {
        display: none;
    }

    .is-completed {

        .step-number {
            display: none;
        }

        .step-done {
            display: inline;
        }
    }
}

.step-content {
    .notification {

        &.is-danger {
            background-color: #f14668;
        }
    }
}

