@media screen and (max-width: 999px) {
  .m-loyalty-table {
    &.is-responsive {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      display: block;
      position: relative;
    }

    &.is-responsive td:empty:before {
      content: ' ';
    }

    &.is-responsive th,
    &.is-responsive td {
      margin: 0;
      vertical-align: top;
    }

    &.is-responsive th {
      text-align: left;
    }

    &.is-responsive thead {
      border-right: solid 2px #dbdbdb;
      display: block;
      float: left;
      display: block;
      padding: 0 10px 0 0;
      border-width: 0 0 1px;
    }

    &.is-responsive thead th::before {
      content: ' ';
    }

    &.is-responsive tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap;
    }

    &.is-responsive tbody tr {
      display: inline-block;
      vertical-align: top;
    }

    &.is-responsive th {
      display: block;
      text-align: right;
    }

    &.is-responsive td {
      display: block;
      text-align: left;
    }

    &.is-responsive th,
    &.is-responsive td {
      border-bottom-width: 2px;
    }

    &.is-responsive tr td:not(:last-child) {
      border: 1px solid $grey-light;
      border-width: 0 0 1px;
    }

    &.is-responsive.is-bordered {
      border-width: 1px;
    }

    &.is-responsive.is-bordered td,
    &.is-responsive.is-bordered th {
      border-bottom-width: 2px !important;
    }

    &.is-responsive.is-bordered:last-child {
      border-width: 2px;
    }
  }
}

@media screen and (max-width: 800px) {
  .is-responsive {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;
  }

  .is-responsive td:empty:before {
    content: ' ';
  }

  .is-responsive th,
  .is-responsive td {
    margin: 0;
    vertical-align: top;
  }

  .is-responsive th {
    text-align: left;
  }

  .is-responsive thead {
    border-right: solid 2px #dbdbdb;
    display: block;
    float: left;
    display: block;
    padding: 0 10px 0 0;
    border-width: 0 0 1px;
  }

  .is-responsive thead th::before {
    content: ' ';
  }

  .is-responsive tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  .is-responsive tbody tr {
    display: inline-block;
    vertical-align: top;
  }

  .is-responsive th {
    display: block;
    text-align: right;
  }

  .is-responsive td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }

  .is-responsive th,
  .is-responsive td {
    border-bottom-width: 2px;
  }

  .is-responsive tr td:not(:last-child) {
    border: 1px solid $grey-light;
    border-width: 0 0 1px;
  }

  .is-responsive.is-bordered {
    border-width: 1px;
  }

  .is-responsive.is-bordered td,
  .is-responsive.is-bordered th {
    border-bottom-width: 2px !important;
  }

  .is-responsive.is-bordered:last-child {
    border-width: 2px;
  }
}

table {
  strong {
    color: inherit;
  }
}

@media screen and (max-width: 600px) {
  table {
    font-size: 12px;
  }
}

.content {
  table.is-responsive {
    tbody tr:last-child {
      td {
        border-width: 0 0 1px;
        border-color: $grey-light;
      }
    }
    thead + tbody tr:last-child {
      td {
        border: 2px solid $grey-light;
        border-width: 0 0 1px;
      }
    }
    th {
      border-color: $grey-light;
      color: $grey-light;
    }
  }
}

.content table td {
  @include until(800px) {
    border-color: $grey-light;
  }
}

.m-table-transactions {
  td {
    &:nth-child(3) {
      text-transform: lowercase;
    }
  }
}



.is-striped-mobile {
  @include until(800px) {
    tr:nth-child(even) {
      background-color: $brand-dark-grey;
    }
    tr:first-child {
      background-color: transparent;
    }
  }
}

 
.content.typography:not(.accordion-content) {
  table {
    margin-bottom: $gap * 2;
    background-color: $brand-dark-greyer;
    td {
      border: 1px solid #111011;
    }
  }
}
