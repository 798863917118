.opened-crypto {
  @include until($tablet) {
    min-height: initial!important;
    height: 125vh!important;

    &-deposit {
      height: 155vh!important;
    }
  }
}

.bonus-is-active {
  .opened-crypto {
    @include until($tablet) {
      min-height: initial !important;
      height: calc(125vh + 200px)!important;

      &-deposit {
        height: calc(160vh + 200px)!important;
      }
    }
  }
}

@media screen and (max-width: $widescreen) and (orientation: landscape) {
  .opened-crypto {
    min-height: initial!important;
    height: 150vh!important;

    &-deposit {
      height: 260vh!important;
    }
  }

  .bonus-is-active {
    .opened-crypto {
      min-height: initial!important;
      height: calc(150vh + 300px)!important;

      &-deposit {
        height: calc(260vh + 300px)!important;
      }
    }
  }
}

@media screen and (min-width: $desktop) and (max-width: $widescreen) and (orientation: landscape) {
  .bonus-is-active {
    .opened-crypto {
      &-deposit {
        height: calc(160vh + 300px)!important;
      }
    }
  }
}

.crypto-wrapper {
  width: 100%;
  display: flex;

  .notification {
    margin-bottom: 0;
  }

  &.deposit {
    .crypto-labels-wrapper {
      div {
        &:nth-of-type(2) {
          height: 150px;

          @include until($tablet) {
            height: 130px;
          }
        }
      }
      height: 1.7rem;
    }

    .bonus-code-wrapper {
      display: flex;
      justify-content: center;

      input {
        padding: 0.2rem 0.5rem;
        font-weight: bolder;
        width: 60%;
        margin-right: 5px;
      }

      button {
        padding: 0.2rem 0.5rem;
        text-transform: uppercase;
        border-radius: 5px;
        background-color: $dark-green;
        color: white;
        border: none;
        font-weight: bolder;
        cursor: pointer;

        &:active, &:hover {
          background-color: darken($dark-green, 5);
        }

        &:disabled {
          background-color: darken($dark-green, 8);
          color: $grey-light;
        }
      }
    }
  }

  a {
    color: black;
    text-decoration: underline;
  }

  .loader-wrapper {
    background-color: $brand-mid-grey;
    height: 128px;
  }

  .crypto-deposit-error {
    margin-bottom: 10px;
    width: 50%;

    i {
      display: block;
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  .qr-code-wrapper {
    min-height: 150px;
  }

  .crypto-banner {
    padding: 10px 15px 10px 45px;
    background-color: #fff;
    border: none!important;
    height: 60px;
    width: 100%;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .crypto-logos {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      img {
        height: 40px;

        &:not(:nth-child(-n+8)) {
          display: none;
        }
      }
    }

    span {
      font-weight: 600;
      color: black;
    }

    @include until($tablet) {
      font-size: 0.8rem;
      padding: 10px 15px 10px 15px;

      .crypto-logos {
        img {
          &:not(:nth-child(-n+3)) {
            display: none;
          }
          height: 30px;
        }
      }
    }
  }

  .crypto-body {
    width: 100%;
    background-color: white;
    padding: 0.2rem;

    .crypto-body-top {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .qr-code {
      background-color: white;
      padding: 5px;
      border: 5px solid black;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    section {
      background-color: $brand-mid-grey;
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &:last-of-type {
        padding-top: 0;

        p {
          font-weight: initial !important;
        }
      }

      p {
        color: white;
        text-align: center;

        &:first-child {
          font-weight: bolder;
        }

        &:not(first-child) {
          font-size: 0.9rem;
        }

        a {
          color: $brand-blue;
        }
      }

      .qr-copy-content {
        display: flex;
        align-items: center;
        column-gap: 20px;

        &.crypto-tag {
          color: white;
          font-weight: bolder;
        }

        span {
          color: $blue;
          font-weight: 600;
          font-size: 1.1rem;
        }

        button {
          border-radius: 5px;
          background-color: $blue;
          color: white;
          border: none;
          font-weight: bolder;
          cursor: pointer;

          &:active {
            background-color: darken($blue, 5);
          }
        }

        @include until(550px) {
          flex-direction: column;
          max-width: 100%;
          margin-bottom: 10px;

          span {
            max-width: 80%;
            word-wrap: break-word;
            text-align: center;
            margin: 5px 0;
          }
        }
      }
    }

    .payment-result {
      margin-top: 10px;
      height: 20px;
      display: flex;
      align-items: center;

      p {
        font-size: 1.1rem;
      }

      .loader-container {
        & > div {
          display: flex;
          align-items: center;
        }
      }
    }

    .crypto-currency-container {
      background-color: white;
    }

    section:first-of-type {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      @include until($tablet) {
        grid-template-columns: .5fr 2.5fr;;
        grid-column-gap: 1rem;
      }

      & > div {
        &:first-of-type {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 100%;
          color: white;
          font-weight: bolder;
          padding-right: 30px;
          text-align: right;

          @include until($tablet) {
            padding-right: 0;
          }

          div {
            &:not(last-of-type) {
              margin-bottom: 10px;
            }
          }
        }

        &:nth-of-type(2) {
          display: flex;
          flex-direction: column;
          align-items: center;

          canvas {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

.custom-select-container {
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  z-index: 99;

  .selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    padding: 0 5px;
    font-weight: bolder;

    i {
      font-size: 30px;
    }

    img {
      height: 20px;
    }
  }

  ul {
    position: absolute;
    background-color: white;
    width: 100%;
    -webkit-box-shadow: 4px 5px 7px -6px #000000;
    box-shadow: 4px 5px 7px -6px #000000;
    min-height: 100px;
    max-height: 300px;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 10px!important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1!important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888!important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555!important;
    }
  }

  li {
    display: flex;
    align-items: center;
    padding: 5px;

    &:hover {
      font-weight: bolder;
    }

    img {
      height: 20px;
      width: 20px;
      margin-right: 25px;
    }
  }
}

.crypto-wrapper {
  &.withdrawal {
    .crypto-body {
      section:first-of-type {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }

    .is-danger {
      margin-bottom: 10px;
      color: red;
      font-weight: bolder;
    }

    .crypto-fields-wrapper {
      align-items: flex-start!important;
      padding-left: 80px;
    }

    .crypto-field {
      &.amount {
        position: relative;

        &.not-empty::before {
          position: absolute;
          top: 2px;
          content: attr(data-currency);
          left: 5px;
          color: black;
        }

        input {
          padding-left: 15px;

          &::placeholder {
            font-style: italic;
          }
        }
      }
    }

    input {
      padding: 0 5px;
      font-size: 1rem;
      width: 75%;
      height: 1.7rem;

      &[name=withdrawalAddress] {
        line-height: 1rem;
        font-size: 0.8rem;
      }
    }

    button {
      width: 75%;
      border-radius: 5px;
      background-color: $blue;
      color: white;
      border: none;
      font-weight: bolder;
      cursor: pointer;
      padding: 2px;

      &:active {
        background-color: darken($blue, 5);
      }

      &.is-loading {
        &::after {
          border-radius: 50%;
          border: 2px solid #fff;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }

    .crypto-field {
      margin-bottom: 8px;
      width: 100%;
    }
  }

  &.withdrawal {
    @include until($tablet) {
      .crypto-body {
        .crypto-fields-wrapper {
          padding-left: 0;
        }

        input {
          width: 100%;
        }

        button {
          width: 100%;
          font-size: 0.8rem;
        }
      }

      .crypto-labels-wrapper {
        font-size: 0.8rem;
        padding-right: 10px !important;

        div {
          height: 1.7rem;
        }
      }
    }
  }
}