.m-promo-nav {
  
    .navbar-start {
        width: 100%;
    }

    @include until($desktop) {
        .m-game-nav-menu.is-active {
            transform: translateX(-50%);
            left: 50%;
            top: 3.9rem;
            background-color: $white;
            
            a {
                color: $brand-grey;
                &.is-active {
                    color: $white !important;
                    background-color: $brand-grey !important;
                }

                &:hover {
                    background-color: $brand-grey;
                    color: $white;
                }
            }
        }
        .level-left {
            margin: auto;
        }
    }

    @include from($desktop) {
        font-weight: bold;
        margin-bottom: $gap*4;

        .navbar-item {
            color: $dark;
            &.is-active {
                background: transparent !important;
                color: $dark;
                text-decoration: underline;
            }
            &:hover,
            &:focus {
                color: darken($brand-light-blue, 10%);
                text-decoration: underline;
                background: transparent !important;
            }
        }
    }
    
    .navbar-burger {
      color: $brand-grey;
    }

    .navbar-menu {
        flex-wrap: wrap;
        width:100%;
        .navbar-item {
            color: $primary;
            &.is-active {
                color: $dark;
                background: white !important;
                text-decoration: none;
            }
        }
    }

    .navbar-item {
        color: $brand-grey;
        border: 0px solid;
        font-size: inherit;
        font-weight: inherit;
        outline: 0;
        cursor: pointer;

        @include until($desktop) {
            width: 100%;
        }

        &.is-active {
            background: $brand-grey !important;
        }
    }

    .m-game-nav-button {
        line-height: 3rem;
        border: 0px solid;
        padding: 0 0 0 15px;
        border-radius: 5px;
        margin: 10px;
        background-color: whitesmoke;
        color: $brand-grey;
        cursor: pointer;

        &:focus {
            outline: 0;
        }
    }
}

.m-promo-article-layout {
    > .level-item {
        margin: $gap 0 $gap*2;
    }
}

.m-promo-nav {
    sup {
        display: inline-block;
        vertical-align: top;
        line-height: 0;
        margin-top: -10px;
        padding-right: 5px;
    }
}