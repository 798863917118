@font-face {
  font-family: 'logos-icons';
  src: url('../fonts/logos-icons.eot?176m4m');
  src: url('../fonts/logos-icons.eot?176m4m#iefix') format('embedded-opentype'),
    url('../fonts/logos-icons.ttf?176m4m') format('truetype'),
    url('../fonts/logos-icons.woff?176m4m') format('woff'),
    url('../fonts/logos-icons.svg?176m4m#logos-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'logos-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-logo-astro-pay:before {
  content: "\e98d";
}
.icon-logo-pay-retailers:before {
  content: "\e98c";
}
.icon-logo-swiffy:before {
  content: "\e98b";
}
.icon-logo-eu-stars:before {
  content: "\e988";
}
.icon-logo-truelayer:before {
  content: "\e989";
}
.icon-logo-noda-pay:before {
  content: "\e98a";
}
.icon-logo-flykk:before {
  content: "\e984";
}
.icon-logo-rapid-transfer:before {
  content: "\e985";
}
.icon-logo-zimpler:before {
  content: "\e987";
}
.icon-logo-sofort:before {
  content: "\e992";
}
.icon-logo-volt:before {
  content: "\e995";
}
.icon-logo-n26:before {
  content: "\e994";
}
.icon-logo-paysafecard:before {
  content: "\e993";
}
.icon-logo-cashtocode:before {
  content: "\e98e";
}
.icon-logo-coinspaid:before {
  content: "\e98f";
}
.icon-logo-mifinity:before {
  content: "\e990";
}
.icon-logo-revolut:before {
  content: "\e991";
}
.icon-logo-ezeewallet:before {
  content: "\e983";
}
.icon-logo-ecopayz:before {
  content: "\e986";
}
.icon-logo-bitcoin:before {
  content: "\e982";
}
.icon-logo-trustly-green .path1:before {
  content: "\e979";
  color: rgb(14, 224, 110);
}
.icon-logo-trustly-green .path2:before {
  content: "\e97a";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path3:before {
  content: "\e97b";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path4:before {
  content: "\e97c";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path5:before {
  content: "\e97d";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path6:before {
  content: "\e97e";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path7:before {
  content: "\e97f";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path8:before {
  content: "\e980";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-logo-trustly-green .path9:before {
  content: "\e981";
  margin-left: -3em;
  color: rgb(0, 0, 0);
}
.icon-coins:before {
  content: "\e978";
}
.icon-logo-curacao-licensing:before {
  content: "\e977";
}
.icon-logo-mastercard-color .path1:before {
  content: "\e96f";
  color: rgb(255, 255, 255);
}
.icon-logo-mastercard-color .path2:before {
  content: "\e970";
  margin-left: -1.2197265625em;
  color: rgb(255, 95, 0);
}
.icon-logo-mastercard-color .path3:before {
  content: "\e971";
  margin-left: -1.2197265625em;
  color: rgb(235, 0, 27);
}
.icon-logo-mastercard-color .path4:before {
  content: "\e972";
  margin-left: -1.2197265625em;
  color: rgb(247, 158, 27);
}
.icon-logo-mastercard-color .path5:before {
  content: "\e973";
  margin-left: -1.2197265625em;
  color: rgb(247, 158, 27);
}
.icon-logo-visa-lozenge .path1:before {
  content: "\e974";
  color: rgb(44, 46, 107);
}
.icon-logo-visa-lozenge .path2:before {
  content: "\e975";
  margin-left: -1.7998046875em;
  color: rgb(255, 255, 255);
}
.icon-logo-klarna-lozenge:before {
  content: "\e976";
}
.icon-logo-neteller:before {
  content: "\e96e";
}
.icon-logo-skrill:before {
  content: "\e96d";
}
.icon-logo-NMItested:before {
  content: "\e968";
}
.icon-logo-HUflag .path1:before {
  content: "\e969";
  color: rgb(203, 33, 61);
}
.icon-logo-HUflag .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(64, 119, 78);
}
.icon-logo-HUflag .path3:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-logo-otpbank:before {
  content: "\e96c";
}
.icon-18-plus:before {
  content: "\e967";
}
.icon-logo-plus-de:before {
  content: "\e966";
}
.icon-logo-twitter-1:before {
  content: "\e911";
}
.icon-logo-facebook:before {
  content: "\e912";
}
.icon-logo-instagram:before {
  content: "\e914";
}
.icon-logo-gambling-integrity:before {
  content: "\e93e";
}
.icon-logo-gamomat:before {
  content: "\e941";
}
.icon-logo-wazdan:before {
  content: "\e943";
}
.icon-logo-pragmatic:before {
  content: "\e945";
}
.icon-logo-netent:before {
  content: "\e946";
}
.icon-logo-amatic:before {
  content: "\e947";
}
.icon-logo-stakelogic:before {
  content: "\e94a";
}
.icon-logo-klarna:before {
  content: "\e958";
}
.icon-logo-neosurf:before {
  content: "\e959";
}
.icon-logo-visa:before {
  content: "\e95a";
}
.icon-logo-kajot:before {
  content: "\e95b";
}
.icon-logo-red-tiger:before {
  content: "\e95c";
}
.icon-logo-maestro:before {
  content: "\e95d";
}
.icon-logo-trustly:before {
  content: "\e95e";
}
.icon-logo-interac:before {
  content: "\e95f";
}
.icon-logo-quickbit:before {
  content: "\e960";
}
.icon-logo-18:before {
  content: "\e961";
}
.icon-logo-FF-Gaming-Group:before {
  content: "\e962";
}
.icon-logo-MGA-cut:before {
  content: "\e963";
}
.icon-logo-secure-SSL:before {
  content: "\e964";
}
.icon-logo-much-better:before {
  content: "\e965";
}
.icon-logo-bf-games:before {
  content: "\e957";
}
.icon-maintenance:before {
  content: "\e956";
}
.icon-blog:before {
  content: "\e954";
}
.icon-dice:before {
  content: "\e955";
}
.icon-VIP:before {
  content: "\e944";
}
.icon-promotions:before {
  content: "\e94b";
}
.icon-contact:before {
  content: "\e94c";
}
.icon-game-history:before {
  content: "\e918";
}
.icon-logo-mastercard:before {
  content: "\e915";
}
.icon-logo-mastercard-colour .path1:before {
  content: "\e94d";
  color: rgb(35, 31, 32);
}
.icon-logo-mastercard-colour .path2:before {
  content: "\e94e";
  margin-left: -1.646484375em;
  color: rgb(255, 95, 0);
}
.icon-logo-mastercard-colour .path3:before {
  content: "\e94f";
  margin-left: -1.646484375em;
  color: rgb(235, 0, 27);
}
.icon-logo-mastercard-colour .path4:before {
  content: "\e950";
  margin-left: -1.646484375em;
  color: rgb(247, 158, 27);
}
.icon-logo-mastercard-colour .path5:before {
  content: "\e951";
  margin-left: -1.646484375em;
  color: rgb(247, 158, 27);
}
.icon-logo-visa-colour:before {
  content: "\e952";
  color: #1a1f71;
}
.icon-logo-neosurf-colour:before {
  content: "\e953";
  color: #ee018d;
}
.icon-logo-leander:before {
  content: "\e948";
}
.icon-logo-microgaming:before {
  content: "\e949";
}
.icon-logo-ainsworth:before {
  content: "\e93f";
}
.icon-logo-omni .path1:before {
  content: "\e900";
  color: rgb(84, 44, 132);
}
.icon-logo-omni .path2:before {
  content: "\e901";
  margin-left: -2.15625em;
  color: rgb(255, 255, 255);
}
.icon-logo-omni .path3:before {
  content: "\e902";
  margin-left: -2.15625em;
  color: rgb(0, 0, 0);
}
.icon-logo-curacao-gaming .path1:before {
  content: "\e903";
  color: rgb(68, 67, 67);
}
.icon-logo-curacao-gaming .path2:before {
  content: "\e904";
  margin-left: -0.857421875em;
  color: rgb(88, 118, 154);
}
.icon-logo-curacao-gaming .path3:before {
  content: "\e905";
  margin-left: -0.857421875em;
  color: rgb(229, 215, 98);
}
.icon-logo-curacao-gaming .path4:before {
  content: "\e906";
  margin-left: -0.857421875em;
  color: rgb(255, 255, 255);
}
.icon-logo-itech-certified .path1:before {
  content: "\e907";
  color: rgb(84, 133, 175);
}
.icon-logo-itech-certified .path2:before {
  content: "\e908";
  margin-left: -3.5556640625em;
  color: rgb(8, 18, 37);
}
.icon-logo-itech-certified .path3:before {
  content: "\e909";
  margin-left: -3.5556640625em;
  color: rgb(255, 255, 255);
}
.icon-logo-itech-certified .path4:before {
  content: "\e90a";
  margin-left: -3.5556640625em;
  color: rgb(255, 255, 255);
}
.icon-responsible-gaming:before {
  content: "\e90b";
}
.icon-ssl:before {
  content: "\e90c";
}
.icon-gaming-labs-certified .path1:before {
  content: "\e90d";
  color: rgb(153, 0, 29);
}
.icon-gaming-labs-certified .path2:before {
  content: "\e90e";
  margin-left: -2.94140625em;
  color: rgb(153, 0, 29);
}
.icon-gaming-labs-certified .path3:before {
  content: "\e90f";
  margin-left: -2.94140625em;
  color: rgb(1, 34, 97);
}
.icon-logo-evolution:before {
  content: "\e910";
}
.icon-logo-betsoft:before {
  content: "\e913";
}
.icon-logo-endorphina:before {
  content: "\e916";
}
.icon-logo-rwg:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e93c";
}
.icon-arrow-up:before {
  content: "\e93b";
}
.icon-arrow-left:before {
  content: "\e93d";
}
.icon-arrow-right:before {
  content: "\e93a";
}
.icon-account:before {
  content: "\e919";
}
.icon-bonus:before {
  content: "\e91a";
}
.icon-deposit:before {
  content: "\e91b";
}
.icon-close:before {
  content: "\e91c";
}
.icon-edit-account:before {
  content: "\e91d";
}
.icon-email:before {
  content: "\e91e";
}
.icon-facebook:before {
  content: "\e91f";
}
.icon-fullscreen-close-o:before {
  content: "\e920";
}
.icon-fullscreen-o:before {
  content: "\e921";
}
.icon-fullscreen:before {
  content: "\e922";
}
.icon-games:before {
  content: "\e923";
}
.icon-history:before {
  content: "\e924";
}
.icon-info-o:before {
  content: "\e925";
}
.icon-live-chat:before {
  content: "\e926";
}
.icon-menu:before {
  content: "\e927";
}
.icon-payout:before {
  content: "\e928";
}
.icon-play-fun:before {
  content: "\e929";
}
.icon-play-real:before {
  content: "\e92a";
}
.icon-question:before {
  content: "\e92b";
}
.icon-review-o:before {
  content: "\e92c";
}
.icon-rules:before {
  content: "\e92d";
}
.icon-search:before {
  content: "\e92e";
}
.icon-sign-in:before {
  content: "\e92f";
}
.icon-sign-out:before {
  content: "\e930";
}
.icon-sign-up:before {
  content: "\e931";
}
.icon-social:before {
  content: "\e932";
}
.icon-support:before {
  content: "\e933";
}
.icon-tick:before {
  content: "\e934";
}
.icon-twitter:before {
  content: "\e935";
}
.icon-verification-default:before {
  content: "\e936";
}
.icon-verification .path1:before {
  content: "\e937";
  color: rgb(121, 180, 0);
}
.icon-verification .path2:before {
  content: "\e938";
  margin-left: -0.9775390625em;
  color: rgb(255, 255, 255);
}
.icon-youtube:before {
  content: "\e939";
}
.icon-logo-mga:before {
  content: "\e940";
}
.icon-my-limits:before {
  content: "\e942";
}
