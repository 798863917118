.pageloader::after {
  z-index: 1;
  display: none;
}

.pageloader {
  background-color: $dark;
  padding-top: 0;

  .title.page-loader {
    margin: 0;
  }

  img {
    transform: translateX(0) translateY(-50%);
    z-index: 99999;
    width: 600px;
    height: auto;
  }
}

