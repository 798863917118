.m-user-consents-window {
  position: relative;

.fixed-scrollable {
  max-height: 400px;
  overflow-y: auto;
  overscroll-behavior: contain;

    @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
      max-height: 400px;
    }
    @include until($desktop) {
      overflow-y: auto;
      overscroll-behavior: none;
    }

  }

  .box {
    .content {
      padding: 10px;
    }
  }

  .buttons {


    @include from(375px) {
      position: sticky;
      bottom: 50px;
      background-color: white;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    @include until(375px) {
      position: sticky;
      bottom: 50px;
      background-color: white;
      padding-top: 20px;
      .button {
        font-size: 0.7rem;
      }
    }

    @include from($tablet) {
      position: static;
      padding: 0;

      .button {
        margin-bottom: 20px;
      }
    }
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 170px;
    color: white;
    font-size: 0.8rem;
    margin: 0 0 20px 20px;
    position: absolute;
    bottom: 10px;
    right: 15px;

    @media screen and (max-width:760px) {
      position: relative;
      bottom: 0;
      right: 0;
      width: 100%;
      font-size: 1rem;
    }
    
    @media screen and (max-width:321px) {
        font-size: 0.8rem;
    }

    & input[type="checkbox"] {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    padding: 10px;
    -webkit-appearance: none;
    border: 2px solid lightgray;
    border-radius: 4px;
    position: relative;

      @media screen and (max-width:760px) {
        width: 15px;
        height: 15px;
        padding: 8px;
      }

      &:checked:after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="rgb(155, 192, 207)"  viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
      display: block;
      position: absolute;
      left: 2px;
      top: 1px;

        @media screen and (max-width:760px) {
          content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="rgb(155, 192, 207)"  viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
          left: 2px;
          top: 1px;
        }
      }
    }
  }
}
