// =========================================================================
// Layout - Sections of Page
// =========================================================================

// =========================================================================
// Footer
// =========================================================================

.footer {
    @include until($tablet) {
        padding: 1.5rem 1.5rem 4rem;
    }

    @include from($desktop) {
        position: relative;
        z-index: 1;
        position: relative;
        z-index: 1;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
        padding: 1.5rem;
    }

    .container.is-fluid {
        max-width: 1915px !important;
        margin: auto;
        padding: 0;
    }

    .separator {
        position: relative;

        p {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -52%);
            background-color: $footer-background-color;

            @include until($desktop) {
                width: 70%;
            }

            @include until($tablet) {
                font-size: 2vw !important;
                width: 80%;
            }
        }
    }

    .icon-logo-red-tiger {
        color: $footer-color;
        cursor: pointer;
    }

    a {
        color: $footer-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    
    i {
        font-size: 300%;

        @include until(460px) {
            font-size: 230%;
        }
    }
    .columns {
        justify-content: center;

        &.menus {
            margin: 0 auto;
            max-width: 1170px;


            @include until($tablet) {
                margin: 0;
                row-gap: 1rem;
            }

            .column {
                min-width: 20%;
                align-items: flex-start;
                text-align: left;

                &:last-child {
                    min-width: 0;
                }

                @include until($tablet) {
                    align-items: center;
                    text-align: center;
                }
            }
        }
        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 0;
        }
    }

    hr {
        height: 1px;
        background-color: $white;
        margin: 2rem 0;
    }

}

.game-footer {
    padding: 2rem 1rem;

     @include until($tablet) {
        display: none;
    }
}

.m-footer-logos {
    @include until(1730px) {
        margin: auto;
        justify-content:center;
        line-height: 55px;
    }

    &-providers {
        max-width: 1840px;
        margin: auto;

        @include from(1268px) {
            padding-bottom: 20px;
        }
        @include until(1670px) {
          font-size: 0.9vw;
        }
        @include until(1290px) {
            font-size: 1.8vw;
        }
        @include until(600px) {
            font-size: 3vw;
        }

        .column {
            flex-grow: 0;
            display: flex;
            justify-content: center;
            margin: 10px 0;
            @include until(1730px) {
                padding: 10px;
                flex-grow: 1;
            }

            @include until(400px) {
                padding: 5px;
                display: flex;
                justify-content: center;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    hr {
        height: 1px;
        background-color: $grey;
    }

    h4, .h4, h5, .h5, h6, .h6 {
	    margin-bottom: 0;
    }
}

.m-footer-logos-providers {
    padding-bottom: 0px !important;

    @include until($desktop) {
        row-gap: 0.5rem;
    }

    &.is-centered {
        justify-content: space-evenly !important;
    }
    .column {
        padding: 0;
        max-height: 90px;
        row-gap: 0.5rem;
        min-height: 80px;

        @include until($tablet) {
            max-height: 40px;
            min-height: auto;
        }
    }

    .image {
        width: 100%;
        max-width: 80%;
        position: initial !important;
        display: flex;
        justify-content: center;
        align-items: center;

        @include until($tablet) {
            min-height: 30px;
        }

        img {
            opacity: 0.5;
            position: initial;
            height: auto;
            max-width: 110px;
        }

        &.is-5by3 {
            padding: 0;
            position: initial !important;
        }
    }

    @include from($widescreen) {
        min-height: 80px;
    }
}

.footer-logos-payments {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75rem;
    
   &.main {
    max-width: 1840px;
    justify-content: center;

    @include from(1595px) {
        row-gap: 0.5rem;
    }

    
    @include until(900px) {
        justify-content: center;
    }

    .column {
        margin: 10px;
        margin: 0;
        min-height: 70px;
        max-height: 80px;
        aspect-ratio: 115/100;
        padding: 8px 10px;

    }
    
    .image {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        margin: auto;
        min-height: 0;
        opacity: 1;
        position: static;
        padding: 0;
        height: auto;
        img {
            max-height: 40px;
            @include from(620px) {
                max-width: 110px;
            }
            @include until(768px) {
                width: 100%;
            }
        }
    }

    @include until($desktop) {
        row-gap: 0.5rem;
    }


    .icon-logo-MGA-cut {
        font-size: 260%;
      }

   

    i {
        @include until($desktop) {
            font-size: 250%;
        }

        @include until($tablet) {
            font-size: 230%;
        }

        @include until(460px) {
            font-size: 160%;
        }
    }
} 

    &:last-of-type {
        justify-content: space-around;

        @include from($desktop) {
            row-gap: 1rem;
        }

        .brand {
            justify-content: space-between;
            flex-direction: row;
            max-width: 330px;
            flex: 1;

            .column {
                justify-content: center;
                width: auto;
            }
        }

        .column:nth-of-type(4) {
            @include from($desktop) {
                width: 100% !important;
                max-width: 400px;
            }
        }
    }

    &:nth-last-of-type(-n+1) {
        & > .column:last-child {
            flex-direction: row;
            justify-content: space-around;
            @include from($tablet) {
            min-width: 450px;
            }

            @include until($widescreen) {
                width:100%;
            }
        }
    }

    .column {
        justify-content: center;
    }
}

.m-footer-copyright {
    @include until($desktop) {
        margin: 1rem 0;
    }
    p,
    a {
        font-size:0.7rem;
    }

    a {
        text-decoration: underline;
    }
}

.m-social-media-icons {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    width:100%;
    max-width: 150px !important;
    margin-left: 1rem;

    @include until($tablet) {
        width: 100%;
        margin: .5rem 0;
    }

    @include until($widescreen) {
        order: 10;
    }

    @include from($widescreen) {
        justify-content: flex-end;
    }

    .icon {
        border: 1px solid;
        border-radius: 50%;
        color: $grey;

        &:hover {
            color: white;
        }
    }

    li {
        display: inline-block;
        margin: 5px;
        text-align: center;

        a:hover {
            text-decoration: none;
        }
    }

    i {
        font-size: 150%;
    }
}

.gamepage-footer-container {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75rem;
    display: flex;

    @include until(1170px) {
        display: grid;
        align-items: center;
        justify-items: center;

        grid-template-columns: 50% 50%;
        grid-template-rows: 60px;

        .footer-text {
            text-align: justify;
        }

        #section-logo-1 {
            grid-column: 1/2;
            grid-row: 1/2;
            width: 100%;
        }
        #section-logo-2 {
            grid-column: 2/3;
            grid-row: 1/2;
            width: 100%;
        }
        #section-text {
            grid-column: 1/3;
            grid-row: 2/3;
            width: 100%;
        }
        .footer-text {
            text-align: center;
        }

    }

    @include until($tablet){
        visibility: hidden;
    }

    .image {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        margin: 5px;
        min-height: 0;
        opacity: 0.9 !important;
    }

    .icon-logo-18 {
        @include from($fullhd) {
            font-size: 350%;
        }
    }

    .icon-logo-MGA-cut {
        font-size: 260%;
    }

   &.main {
    max-width: 1840px;
    justify-content: center;

    @include until(1300px) {
        max-width: 100%;
    }

    @include until(900px) {
        justify-content: center;
    }




    @include from(1595px) {
        row-gap: 0.5rem;
    }
    @include until($desktop) {
        row-gap: 0.5rem;
    }

} 


    &:last-of-type {
        justify-content: space-around;

        @include from($desktop) {
            row-gap: 1rem;
        }

        .brand {
            justify-content: space-between;
            flex-direction: row;
            max-width: 330px;
            flex: 1;

            @include until($tablet) {
                max-width: 100%;
            }

            .column {
                justify-content: center;
                width: auto;
                margin-bottom: 0;
            }
        }

        .column:nth-of-type(4) {
            @include from($desktop) {
                width: 100% !important;
                max-width: 400px;
            }
        }
    }

    .columns{
        align-items: center;
    }
    #section-logo-1 {
        margin-bottom: 0;
    }

    &:nth-last-of-type(-n+1) {
        & > .column:last-child {
            flex-direction: row;
            justify-content: space-around;
            border: 2px solid yellow;
            @include from($tablet) {
                min-width: 340px;
            }

            @include until($desktop) {
                width:100%;
            }
        }
    }
}



#apg-seal-container {
    width: 50px;
    margin-top: -10px;
    
    + .icon-logo-curacao-licensing {
    display: none;
    }
}