.m-promo-columns {

    &-wrapper {
      &.m-promo-nav {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      .column {
        &:first-child {
          @include from($desktop) {
            padding-right: 14px;
          }
        }
      }
    }
    &.columns {
        margin-bottom: 0;
        
        @include from($desktop) {
            margin-bottom: $gap*3;
        }

        > .column {
            @include until($desktop) {
                margin-bottom: 0;
            }
        }
    }

    &-aside {
        margin: $gap/2;
        position: relative;

        @include from($desktop) {
            height: 470px;
        }
    }

    &-column-aside {
        max-width: calc(388px + 15px);
    }

    .m-grid-item {
      img {
        opacity: 1;
        transition: opacity ease 200ms;
      }
        &:hover {
          img {
            opacity: 0.8;
            transition: opacity ease 200ms;
          }
        }
    }

}

.m-promo-section {
    padding: 1.5em 0;
    @include from($desktop) {
        padding-top: 3rem;
    }
}

.m-promo-layout,
.m-blog-layout {
    @include until($desktop) {
        display: flex;
        flex-direction: column;
        > div {
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
            &:nth-child(3) {
                order: 3;
            }
            &:nth-child(4) {
                order: 2;
            }
            &:nth-child(5) {
                order: 5;
            }
        }
    }
}
.m-promo-article-layout,
.m-blog-article-layout {
    @include until($desktop) {
        display: flex;
        flex-direction: column;
        > * {
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
            &:nth-child(3) {
                order: 3;
            }
            &:nth-child(4) {
                order: 4;
            }
            &:nth-child(5) {
                order: 5;
            }
        }
    }
}

.m-blog-list {
    .m-promo-widget-item-text {
        padding: 5px 10px;
        line-height: 1.3;
    }
}

.m-promo-blog-article {
    margin: 5px;
    border-radius: 8px;
    @include from($desktop) {
        margin: 5px 60px;
    }

    > figure {
        @include from($tablet) {
        padding-top: $gap*2;
        margin-left: $gap*2;
        margin-right: $gap*2;
        }
    }

    &.content {
      .button {
        display: block;
        margin: auto;
        width: fit-content;
        text-decoration: none;
        max-width: 100%;
        white-space: break-spaces;
        height: auto;
      }
    }
}

.m-promo-blog-article-content {

    @include from($tablet) {
        padding: $gap*4;
        margin-bottom: $gap*4;
    }

    padding: $gap;
    margin-bottom: $gap*2;
}

.m-promo-blog-title {
  margin-top: 0 !important;
  text-align: center;
}

.m-promo-blog-image {
  width: 100%;
  border-radius: 8px 8px 0 0;
  margin-bottom: $gap*4;
}

.m-promo-video-area {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;

  video {
    max-width: none;
    transform: translateX(-50%);
    margin-left: 50%;
    @include until($tablet) {
      height: 390px;
    }
  }
}

.m-promo-banner-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 370px;
  position: relative;

  .button {
    background: white;
    color: $dark;
    border-color: white;

    &:hover {
      background: darken(white, 20%);
      border-color: darken(white, 20%);
    }
  }
}

.m-promo-news-banner {
  position: relative;
}