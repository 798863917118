.m-tabs {
  .m-tab-text {
    @include until($tablet) {
      font-size: 16px;
    }
    @include until(600px) {
      font-size: 13px;
    }
  }
  .button {
    height: 100%;
  }
}

.m-tab-panel {
  display: none;

  &.is-active {
    display: block;
    width: 100%;
    // height: 100%;
    .piq-cashier-wrapper {
      right: 10px;
      bottom: 90px;
      left: 10px;
      top: 120px;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;

      @include from($tablet) {
        position: fixed;
        top: 140px;
        bottom: 20px;
        left: 40px;
        right: 40px;
      }

      @include until($tablet) {
        overflow-y: hidden;
        height: 100%;
      }

      iframe {
        width: 100%;
        @include from($desktop) {
          min-height: 700px !important;
        }
      }
    }
  }
}

.m-bonus-slider.is-active + .modal-card-body .piq-cashier-wrapper {

  @include until(1280px) {
    top: 310px;
  }
  @include from(1280px) {
    top: 340px;
  }
  @include until($desktop) {
    top: 320px;
  }
  @include until($tablet) {
    overflow-y: hidden;
    height: 100%;
  }
}

.m-bonus-slider-simple.is-active + .modal-card-body .piq-cashier-wrapper {
  top: 320px;
  @include until($tablet) {
    overflow-y: hidden;
    height: 100%;
  }
}