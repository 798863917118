@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

#solitics-popup-maker {
  .solitics-modal-content {

    max-height: 100vh !important;
    padding-bottom: 20px;
    .solitics-modal-msg,
    .solitics-iframe-popup {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: auto !important;
      max-height: none !important;
    }

    .solitics-close-button {
      top: 1rem !important;
      right: 1.5rem !important;
      width: 50px;
      height: 50px;
      padding-top: 0.25rem;
      border: none !important;
      outline: 2px solid white;
      opacity: 0;

      @include until($tablet) {
         top: 1rem !important;
         right: 0.5rem !important;
      }

      &:hover,&:focus {
        background: none;
        box-shadow: 0 0 0 2em rgba(#fff,0);
      }
    }
  }
}

// #solitics-popup-maker {
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
// }

// #solitics-popup-maker .solitics-iframe-content {
//   max-height: none !important;
// }

// .solitics-modal-content {
//   position: static !important;
//   transform: translate(0%, 0) !important;
// }

// .m-solitics-modal__content {
//   @include until($tablet) {
//     padding: 0 10px !important;
//   }

//   .popup__title {
//     max-width: 100%;
//     width: 100%;
//   }
// }

