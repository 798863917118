// =========================================================================
// Important - Cascade Dependant Code
// =========================================================================

.grecaptcha-badge {
  bottom: 80px!important;
  visibility: hidden;
}

.custom-password-field {
  .icon-visibility {
    pointer-events: auto!important;
  }
}

// force the slides to take full height of the hero promotion widget
.m-promo-widget {
  .slider-wrapper.axis-vertical {
    height: 182px !important;
  }
}
