.m-responsible-gaming-tools {

    .m-select-label {
        flex-grow: 0;
        @include from($tablet) {
            padding-left: $gap;
        }

        @include from($desktop) {
            padding-left: $gap*2;
        }

        + .control {
            @include from($tablet) {
                padding-right: $gap*2;
            }
        }
    }

    .m-update-field {
        position: absolute;
        top: 5px;
        right: 5px;
        max-width: 80px !important;
        @include until($tablet) {
            top: 4px;
        }
    }

    .m-select-label-grow {
        flex-grow: 3;
    }

    .button {
        @include from($tablet) {
            width: 100%;
            max-width: 185px;
            display: flex;
            margin: auto;
        }
    }

    .column {
        @include until($tablet) {
            padding-bottom: $gap*2;
            margin-bottom: $gap;
        }
    }

    .control {
        width: 100%;
    }
}

.m-responsible-gaming-tools-status {
    .button.is-danger {
        &:hover {
            background-color: $danger;
            cursor: default;
        }
    }
    .button.is-success {
        &:hover {
            background-color: $success;
            cursor: default;
        }
    }
}

.m-responsible-gaming-tools-currency {
    position: relative;
    top: auto !important;

    &:before  {
        content: attr(data-currency);
        display: block;
        position: absolute;
        left: $gap;
        top: 50%;
        transform: translateY(-50%);
    }
}


.switch[type="checkbox"].is-large + label {
    display: inline-flex;
}

.m-responsible-gaming-tools {



    .switch[type="checkbox"].is-large+label::before,
    .switch[type="checkbox"].is-large+label:before {
        content: attr(data-off-text);
        color: white;
        font-size: 13px;
        line-height: 32px;
        padding-right: 8px;
        text-align: right;
    }

    .switch[type="checkbox"].is-success:checked+label::before,
    .switch[type="checkbox"].is-success:checked+label:before {
        content: attr(data-on-text);
        color: white;
        font-size: 13px;
        line-height: 32px;
        padding-left: 10px;
        text-align: left;
    }

}

.m-responsible-gaming-tools-limits {

    .tag {
        display: block;
        height: auto;
        margin-top: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        white-space: inherit;
        margin-right: 20px;
    }

    > .column {
        @include until($tablet) {
            padding-bottom: 0;

            &:first-child {
                margin-bottom: 0;
            }
        }
    }

    .m-rg-notification {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .m-rg-label {
        @include from($tablet) {
            padding-right: 20px;
        }
    }
}

.accordions {
    .accordion {
        .m-rg-active,
        .m-rg-active.toggle {
            &.accordion-header,
            &.accordion-header:hover {
                background-color: #292f3c;
                strong,
                i {
                    color: grey !important;
                }
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
#depositAmount::-webkit-outer-spin-button,
#depositAmount::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#depositAmount {
    -moz-appearance: textfield;
}
