.m-game-provider-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  &__content {
    position: fixed;
    top: 100px;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #131521;
    width: 600px;
    
    @include until($tablet) {
      width: 100%;
    }
    min-height: 400px;
    padding: 20px 30px 20px;
    overflow: hidden;
    border-radius: 6px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: auto;
    overscroll-behavior: contain;
    height: calc(100% - 75px);
    align-content: flex-start;
    margin-left: -5px;

    &::-webkit-scrollbar {
      width: 0.5em;
    }
     
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.3);
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      outline: 1px solid slategrey;
    }

    & > button {
      cursor: pointer;
    }
  }
  .input {
    margin: 5px 0 20px;
  }
  .icon {
    position: absolute;
    top: 5px !important;
    left: 5px !important;
  }
  .delete {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .control {
    margin-top: 10px;
  }
}

.m-game-provider-button {
  display: inline-block;
  width: 33.3333%;
  background: transparent;
  border: none;
  padding:0;
  font-size: 1rem;

  @include until(560px) {
    width: 50%;
  }
  &__content,
  &__empty {
    padding: 10px;
    margin: 5px;
    background-color: #212633;
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: white;
    img {
      max-height: 100%;
      max-width: 65%;
    }
    &:hover {
      background-color: rgba(33, 38, 51, 0.5);
    }
  }
}

.m-game-provider-button.is-active {
  .m-game-provider-button__content {
    border: 1px solid $primary;
  }
}