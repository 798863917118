.m-bonus-banner {
    height: 100%;
    padding: $gap;
    border-radius: 30px;
    color: white;
    text-align: center;
    min-height: 590px;

    @include until($tablet) {
        padding: $gap $gap $gap*3;
        min-height: 0;
    }
    
    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include from($tablet) {
            height: 100%;
        }

        .columns {
            flex-wrap: wrap;
        }
    }

    &-title {
        font-size: 200%;
        font-weight: 800;
        line-height: 0.8;

        @include from($desktop) {
            font-size: 500%;

            small {
                font-size: 80%;
            }
        }

        @include from(450px) {
            font-size: 400%;
        }

       
    }

    &-title-sub {
        margin: 10px 0;
    }

    &-text {
        font-size: 60%;

        @include from(480px) {
            font-size: 40%;
        }


        @include from(650px) {
            font-size: 70%;
        }

    }

    strong {
        color: white;
    }
}

.m-bonus-banner-tandcs {
    padding-bottom: $gap*2;
    color: $grey-light;
    a {
        color: $grey-light;
        text-decoration: underline;
    }
}