// =========================================================================
// Mixins
// =========================================================================

// =========================================================================
// Media Queries - @include breakpoint(2025) {}
// =========================================================================

@mixin breakpoint($point) {

    @media (max-width: $point+px) { @content; }

}

@mixin breakpointMin($point) {

    @media (min-width: $point+px) { @content; }

}

// =========================================================================
// States - @include state() { color: $white; }
// =========================================================================

@mixin state() {
	&:hover,
	&:active,
	&:focus {
		@content;
	}
}

// =========================================================================
// RGBA - @include rgba(background-color, $red, 0.8, $red);
// =========================================================================

@mixin rgba($property, $color: #000, $value: 0.5, $fallback: #000) {
	#{$property}: rgb(red($fallback), green($fallback), blue($fallback));
	#{$property}: rgba($color, $value);
}

// =========================================================================
// Transition - @include transition(all, 0.3s, linear);
// =========================================================================

@mixin transition($transition-property, $transition-time, $method) {
	transition: $transition-property $transition-time $method;
}

@mixin no-transition($transition-time) {
	transition: none;
}

// =========================================================================
// Border Radius - @include border-radius(5px); @include border-top-radius(10px);
// =========================================================================

@mixin border-radius($radius) {
	border-radius: $radius;
	background-clip: padding-box;
}

@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

// =========================================================================
// Box Shadow - @include box-shadow(inset, 0, 1px, 1px, rgba(0, 0, 0, 0.5));
// =========================================================================

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
	box-shadow:$top $left $blur $color #{$inset};
}

// =========================================================================
// Opacity - @include opacity(.8);
// =========================================================================

@mixin opacity($trans) {
	zoom: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$trans * 100})";
	opacity: $trans;
}

// =========================================================================
// Box Sizing - @include box-sizing(border-box);
// =========================================================================

@mixin box-sizing($box-model) {
	box-sizing: $box-model;
}

// =========================================================================
// Retina Images - @include image-2x("logo2x.png", 100px, 25px);
// =========================================================================

@mixin image-2x($image, $width, $height) {
	@media (min--moz-device-pixel-ratio: 1.3),
			(-o-min-device-pixel-ratio: 2.6/2),
			(-webkit-min-device-pixel-ratio: 1.3),
			(min-device-pixel-ratio: 1.3),
			(min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

// =========================================================================
// Arrows - @include arrow(15px, #1e252b);
// =========================================================================

@mixin arrow($size, $background) {
	background: $background;
	display: inline-block;
	padding: $size;
	position: relative;
	margin-left: $size;
	&:before {
		content: '';
		position: absolute;
		left: -$size;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		border-top: ($size / 2) solid transparent;
		border-right: $size solid $background;
		border-bottom: ($size / 2) solid transparent;
	}
}

// =========================================================================
// Clearfix - @include clearfix();
// =========================================================================

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// =========================================================================
// Hide Text - @include hide-text();
// =========================================================================

@mixin hide-text {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

// =========================================================================
// Inline Block - @include ie7-hack();
// =========================================================================

@mixin ie7-hack {
    *display: inline;
    zoom: 1;
}

// =========================================================================
// Gradients - @include gradient-left-gray();
// =========================================================================

@mixin gradient-tran-gray {
    background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(197,197,197,1) 50%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(50%,rgba(197,197,197,1)), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(197,197,197,1) 50%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5c5c5', endColorstr='#c5c5c5',GradientType=1 );
}

.ie9 .gradient-tran-gray {
    filter: none;
}

// =========================================================================
// Grayscale @include grayscale-enabled();
// =========================================================================

@mixin grayscale-enabled {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
}

@mixin grayscale-disabled {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
}

@function text-stroke($size: 2, $color: #fff, $correction: 0) {
	$radius: $size - $correction;
	$stroke: ();

	@for $i from -$size through $size {
		@for $k from -$size through $size {

			$x: $k;
			$y: $i;

			@if $k > 0 {
				$x: $k - 0.5;
			} @elseif $k < 0 {
				$x: $k + 0.5;
			}

			@if $i > 0 {
				$y: $i - 0.5;
			} @elseif $i < 0 {
				$y: $i + 0.5;
			}

			@if ($x*$x + $y*$y <= $radius*$radius) {
				$stroke: append($stroke, $i*1px $k*1px 0 $color, comma);
			}
		}
	}

	@return $stroke;
}

@mixin text-stroke($size: 2, $color: #fff, $correction: 0) {
	text-shadow: text-stroke($size, $color, $correction);
}