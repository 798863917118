.m-cashier-first-step {
  width: 100%;
  background-color: black;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 200px);
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 2;
    max-height: 175px;
    min-height: 175px;

    @include from(768px) {
      max-height: 200px;
      min-height: 200px;
    }

    @media only screen and (max-height: 680px) {
      max-height: 140px;
      min-height: 140px;
      padding-top: 10px;
    }

    .button {
      margin: 0 auto;
    }
  }
}

.m-cashier-accordion {

  width: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    width: 100%;
    background: transparent;
    border: 0px solid;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    color: $grey-light;
    cursor: pointer;

    @media only screen and (max-height: 680px) {
      padding: 10px 30px;
    }

    &-text {
      display: flex;

      .tag {
        margin-left: 10px;
        margin-top: -10px;
        transform: scale(.75);

        @media only screen and (max-height: 680px) {
          margin-top: -1px;
        }

        &.is-danger {
          color: black;
        }
      }
    }
    i {
      font-size: 36px;
      line-height: 0.75;
    };
  }

  &__item {
    background-color: black;
    color: $grey;

    &:first-of-type {
      .m-cashier-accordion__header {
        border-bottom: 0;
      }
    }

    i {
      transition: all ease 200ms;
      transform: rotate(0);
    }

    .m-cashier-accordion__success-content {
      padding-top: 20px;
    }

    &:nth-of-type(2).is-active {
      overflow-y: visible;
    }

    @media only screen and (max-width: 460px) {
      #js-cashier-bonus-input {
        .control {
          width: 50%;
        }
      }
    }

    &.is-active {
      i {
        transition: transform ease 200ms;
        transform: rotate(-90deg);
      }

      max-height: calc(100vh - 300px);
      overflow-y: scroll;

      @media only screen and (max-width: 900px) and (orientation: landscape) {
        max-height: initial;
        min-height: calc(100vh - 300px);
      }

      @include until($tablet) {
        max-height: 400px;
      }

      .m-cashier-accordion__content {
        @media only screen and (max-height: 680px) {
          padding: 10px 30px;
        }

        padding: 20px 30px;
        max-height: 2000px;
        transition: all ease 200ms;
      }

      .m-cashier-accordion__header {
        color: white;
      }
    }
  }

  &__content {
    padding: 0 30px;
    max-height: 0;
    overflow: hidden;
    transition: all ease 200ms;
    background-color: $dark;

    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $grey;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: $grey;
    }
    input:-ms-input-placeholder {
      /* IE 10+ */
      color: $grey;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: $grey;
    }

    .field {
      margin-bottom: 0;

      input {
        color: white!important;
      }

      &.is-grouped {
        justify-content: center;
      }
    }

    .columns {
      flex-wrap: wrap;
      justify-content: center;
    }

    input.is-danger {
      background-color: #f2d8d8 !important;
      color: black!important;
    }
  }
}

.m-cashier-promotion-item {
  border: 2px solid white;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex:1;
  width: 100%;

  @include from(768px) {
    margin-bottom: 0;
  }
  @media only screen and (max-height: 680px) {
    margin-bottom: 0px;
  }


  &.placeholder-container {
    @media only screen and (max-width: $widescreen) and (orientation: landscape) {
      max-width: 80%;
      width: 100%;
    }
  }

  @media only screen and (max-width: $widescreen) and (orientation: landscape) {
    display: inline-block;
    width: auto;
  }

  img {
    vertical-align: bottom;
  }

  .promotion-placeholder {
    display: flex;
    position: relative;
    flex: 1;
    height: 161px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(182,211,219,1) 7%, rgba(240,250,252,1) 58%, rgba(205,206,207,1) 93%);

    @include until(400px) {
      h3 {
        font-size: 1rem!important;
      }
    }

    @media only screen and (max-width: $widescreen) and (orientation: landscape) {
      h3 {
        font-size: 0.9rem!important;
      }
    }

    #claim-button {
      position: absolute;
      bottom: 5px;
      font-size: 0.8rem;
      line-height: 0.8rem;
      color: white;
      background-color: $brand-dark-greyer;
      padding: 5px 10px;
    }
  }
}

.m-cashier-promotion-detail {
  padding: 20px 30px;
  position: relative;

  .delete {
    display: block!important;
    position: absolute;
    right: 20px;
    background-color: white;

    &:before,
    &:after {
      background-color: black;
    }
  }
}

.m-cashier-promotion__title {
  font-weight: 800;
  line-height: 1.25;
  margin-bottom: 10px;
}

.tag {
  &.is-rounded {
    border-radius: 20px;
  }
}

.m-cashier-welcombo {
  position: relative;
  height: calc(100vh - 159px);

  &__holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
    position: relative;
  }

  &__container {
    padding: 20px 5px;
    position: relative;
    flex: 1;
    margin-top: -20px;

  }

  &__parent {
    margin-top: 10px;
  }

  &__button {
    text-align: center;
    padding: 20px 0;

    .button {
      span {
        line-height: 1;
      }
    }
  }

  input[type=range] {
    width: 100%;
    margin: 13.3px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    background: #000000;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  input[type=range]::-webkit-slider-thumb {
    margin-top: -13.5px;
    width: 35px;
    height: 35px;
    background: #afafaf;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
    box-shadow: 0 0 10px rgb(0,0,0);
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #0d0d0d;
  }
  input[type=range]::-moz-range-track {
    background: #000000;
    border: 0.2px solid #010101;
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    width: 35px;
    height: 35px;
    background: #afafaf;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 50px;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 18.9px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  input[type=range]::-ms-fill-lower {
    background: #000000;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-fill-upper {
    background: #000000;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-thumb {
    width: 35px;
    height: 35px;
    background: #afafaf;
    border: 0px solid rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #000000;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #0d0d0d;
  }

  .delete {
    display: block!important;
    position: absolute;
    top: 25px;
    right: 20px;

  }

}

.m-cashier-welcombo__bg {
  position: absolute;
  height: 100%;
  width: 100%;
  /*background-image: url(../static/bg-welcombo.jpg);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(3px);
  box-shadow: 0 0 40px inset rgb(0,0,0);

}

.m-cashier-welcombo__range {
  height: 30px;
  border-left: 4px solid;
  border-right: 4px solid;
  padding: 0;
}

.m-cashier-welcombo__label {
  font-weight: 800;
  line-height: 1;
  &-amount {
    font-size: 24px;

    @include from($tablet) {
      font-size: 34px;

    }
  }

  &-text {
    font-size: 12px;
    @include from($tablet) {
      font-size: 18px;

    }
  }
}

#js-promotions-content,
#js-cashier-welcombo {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background: black;
    z-index: 9999;
    // weird ios z-index stacking bug 
    transform: translateZ(1px);
  }

// use the css has selector to hide the cashier first step body when promotion chooser is active,
// this fixes a bug on iphone 13 where the hidden first steps deposit button was still clickable,
// causing deposit without bonus applied.
#js-cashier-accordion:has(.m-cashier-promotions.m-cashier-accordion__content.is-hidden) {
  .m-cashier-first-step__body {
    display: none;
  }
}
