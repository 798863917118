.m-seo-accordion {
    padding-top: $gap*6;
    padding-bottom: $gap*6;
    position: relative;
    max-height: 4000px;
    transition: max-height ease 1s;
    overflow: hidden;
    background-color: black;

    &.container.is-fluid {
        margin-left: 0;
        margin-right: 0;

        .columns {
            max-width: $container-width !important;
            margin: auto;
        }

    }

    @include until($desktop) {
        max-height: 6000px;
    }

    &:after {
        content: '';
        display: block;
        height: 140px;
        background: linear-gradient(rgba(255,255,255,0), $footer-background-color);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        display: none;

    }

    &.is-closed {
        max-height: 200px;
        transition: max-height ease 1s;
        &:after {
            display: block;
        }
    }


    .column {
        padding: 0 $gap*2;
    }

    .icon-arrow-down {
        font-size: 0.75rem;
        margin-left: 10px;
        vertical-align: middle;
    }

    .m-seo-accordion-title {
        button {
            color: $primary;
            padding: 0;
            border: 0px solid;
            background-color: transparent;
            font-size: inherit;
            font-weight: inherit;
            cursor: pointer;
            
            &:hover {
                color: $success;
            }
        }
    
    }
    ul, ol {
        list-style-position: inside;
        margin: 0;
    }
    a {
        text-decoration: underline;
    }

    h2, h3, h4, h5, h6 {
        @extend .is-size-4;
    }

}