.m-overview-columns {
    align-items: flex-start;
}

.m-overview-column {
    max-width: 180px;

    .menu {

        @include until($tablet) {

            display: none;

            &.is-active {
                display: block;
            }

        }

    }
}

.m-overview-info {
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .columns {

    }

    .card {
        width: 100%;
    }

    .card-header-title {
        justify-content: center;
    }

    @include until($tablet) {
        .card-content {
            padding-left: 0;
            padding-right: 0;
        }
    }


}

.m-balance {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}