.m-account-wrapper,
.m-page-wrapper {
    background-size: 100% auto;
    padding: 40px 0;

    .box {
        line-height: 1.4;
    }
}

.m-account-wrapper {
    .notification {
        &.is-success {
            background: $primary;
            color: $brand-grey;
        }
    }

    .button {
        &.is-success {
            background: $secondary !important;
            color: white !important;
            border-color: $secondary;
            &:hover {
                border-color: $secondary;
            }
        }
    }
}

.m-account-icons {

    .Limits {
        display: none;
    }

    .box {
        margin-left: 0;
        margin-right: 0;
        -webkit-justify-content: center;
        -webkit-box-pack: center; /* justify-content */
        margin-bottom: 20px;
        border-radius: 0px;
        .columns {
            text-align: center;
            @include until(1386px) {

                max-width: 550px;
                margin: 0 auto;

            }
        }

    }

    p {
        font-size: 13px;
        font-weight: bold;
    }

}

.m-account-auto-margin {
    margin: 0 auto;
}

.m-account-nav {
    .m-game-nav-menu {
        @include until($desktop) {
            background-color: white;
        }
        .is-active {
          color: white !important;
            @include from($desktop) {
                background-color: white !important;
                color: $dark !important;
            }
        }
    }
}

.bonus-tables {
    .content {
        .active-bonuses {
            td {
                vertical-align: middle;
            }

            th:last-child {
                min-height: 43px;
            }
        }
    }
}