.m-top-nav-brand + .m-top-nav {
    @include from ($desktop) {
        margin-left: 200px;
    }
}

.m-top-nav {

    @extend .navbar;
    position: static;
    min-height: 0;
    @include until(1240px) {
        font-size: 1vw;
    }
    
    @include until($desktop) {
        font-size: 18px;
    }
    
    > .container {
        max-width: $container-width !important;
        margin: auto;
        min-height: 0;
        padding-left: $gap;
        padding-right: $gap;
    }

    &-container {
        position: relative;
        z-index: 9999;
        padding: 0 $gap 0 0;

        .fullscreen-mode & {
            i.fa-expand-arrows-alt {
                &:before {
                    content: "\f2d2";
                }
            }
        }
    }


    &-item {

        @extend .navbar-item;
        min-height: $navbar-height;
        text-shadow: 0 2px #000000;
        transition: background-color ease 250ms;

        &:hover,
        &:focus {
          transition: background-color ease 250ms;
          text-shadow: 0 0 0 !important;
        }

        &.is-active {
            text-shadow: 0 0 0 !important;
        }

        @include until($desktop) {
            min-height: 0;

            &.is-active {
              background-color: white;
              color: $dark;
            }
        }

        i {
            margin-left: 4px;
        }



    }

    &-menu {

        display: flex;
        width: 100%;

        @include until($desktop) {
            overflow: auto;
            .notification-active & {
                margin-top: 62px;
            }
        }

        .level {
            width: 100%;

        }

        .level-left {
            @include until($desktop) {
                display: block !important;
                width: 100%;
            }
        }

        @include until($desktop) {
            display: block;
            background: linear-gradient(to right, #53505B, #25153C);
            left: -100%;
            right: 100%;
            position: fixed;
            top: 80px;
            z-index: 31;
            bottom: 0;
            transition: all ease 300ms;

            &.is-active {

                left: 0;
                right: 0;
                transition: all ease 300ms;
                
            }

            .navbar-item {
                padding: 20px;
                border-bottom: 1px solid $light;
            }
        }

    }

    &-brand {
        width: 225px;
        position: relative;
        z-index: 10;
        padding: 8px 0 0 0;
        @include from ($desktop) {
            position: absolute;
            z-index: 100;
            width: 195px;
            top: 0;
        }

        &.no-top-menu {
            margin-left: 55px;
        }

        @include until($desktop) {
            width: 100px;
            margin-right: 0 !important;
            padding: 0;

            img {
                width: 100px;
                margin-right: -17px;
            }
        }

    }

    &-buttons {
        .dropdown {
            margin-right: 0.5rem;
        }

    }

   .m-language-menu {
       @include until($desktop) {
           display: none;
       }
   }
}

.m-header-account-sign-in {
    .field {
        margin-bottom: 0;
        margin-top: 0.75rem;
    }

    .control.has-icons-right .icon {
        height: 2.25em;
    }

    .input {
        font-size: 0.9rem;
    }

    .button {
        font-size: 0.9rem;
    }

    .level {
        margin-bottom: 0;
    }
}

.m-game-login-area {
    .button {
        font-size: 0.9em;

        
        &#modal-signup-button {
            color: white;
        }
        &.is-small {
            border-radius: 4px;
        }
    }
    .input {
        &.is-small {
            border-radius: 4px;
            font-size: 0.9rem;
            @include until ($widescreen) {
                font-size: 0.75rem !important;
            }
        }
        + .icon.is-small {
            @include from ($widescreen) {
                font-size: 0.9rem !important;
            }
        }
    }

    .field {
        display: flex;
        align-items: center;
        margin: 0 5px;
        
    }
}

.m-balance {
    line-height: 1.2;
}

.mobile-sign-in {
    padding: 7px 10px 0;
    background: transparent;
    border: 0px solid;
    font-size: 1rem;
    cursor: pointer;
    color: white;

    i {
        font-size: 2.5rem;
    }
    p {
        margin: 3px 0;
    }
}

.nav-bar-burger-holder {
    padding-bottom: 3px;
    padding-left: 3px;
    color: white;

    .navbar-burger {
        color: white;
        span {
            width: 30px;
            height: 2px;
            left: 10px;

            &:nth-child(1) {
                top: calc(50% - 11px);
            }

            &:nth-child(3) {
                top: calc(50% + 10px);
            }
        }

        &.is-active {

            span {
                &:nth-child(1) {
                   top: calc(50% + -5px);
                }

                &:nth-child(3) {
                    top: calc(50% + 5px);
                }
            }

        }
    }
}

.m-mobile-nav-icon {
    margin-right: 20px;
    width: 45px;
}

#verification-code-enter {
    width: 110px;
    display: block;
    background: $brand-mid-grey;
    padding: 5px;
    z-index: 2;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);

    &:hover {
        text-decoration: underline;
    }

    i {
        margin-right: 5px;
    }
}

.m-login-fields {
    .button {
        border: 2px solid $success;
        color: $success;

        &#login {
            color: white;
            border-color: #dbdbdb;

            &:hover {
                background: $brand-grey;
            }
        }
    }
}